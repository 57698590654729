import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import * as ModalStories from '@stories/modal.stories.js';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const Example = makeShortcode("Example");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const FilledButton = makeShortcode("FilledButton");
const Dont = makeShortcode("Dont");
const GetInTouch = makeShortcode("GetInTouch");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#how-to-use-a-modal"
            }}>{`How to use a modal`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#types-of-modal"
            }}>{`Types of modal`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#copy-guidelines"
            }}>{`Copy guidelines`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "how-to-use-a-modal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-a-modal",
        "aria-label": "how to use a modal permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use a modal`}</h2>
    <p>{`Modals are used to focus the user’s attention on a single task or an urgent piece of information. They can be used to confirm a user decision, notify the user of  important information, or when an immediate response is required from the user. `}</p>
    <p>{`When using modals: `}</p>
    <ul>
      <li parentName="ul">{`Use them sparingly as they are disruptive to the user’s experience`}</li>
      <li parentName="ul">{`Only give a single piece of information or require a single task to be completed. If you need the modal to do more than one thing, consider using a page instead`}</li>
      <li parentName="ul">{`Focus should automatically move to the modal container so it can be easily accessed by keyboard users`}</li>
      <li parentName="ul">{`Once the modal is closed, the focus state should return to the button that launched the modal`}</li>
      <li parentName="ul">{`The modal must be able to be closed by using the 'esc' key`}</li>
      <li parentName="ul">{`Focus should be limited to the modal meaning mouse and keyboard users should not be able to intereact with content outside of the modal while it is open`}</li>
      <li parentName="ul">{`When the modal is closed, focus should be returned to the control that opened the modal initially`}</li>
      <li parentName="ul">{`Provide context to screen reader users by announcing the modal using when it is opened`}</li>
    </ul>
    <p>{`If you need to help users understand something on a page through supporting text, use a tooltip instead. `}</p>
    <h2 {...{
      "id": "types-of-modal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#types-of-modal",
        "aria-label": "types of modal permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Types of modal`}</h2>
    <Example stories={ModalStories} component={'Standard'} stretch mdxType="Example" />
    <h3>{`Dialog modal`}</h3>
    <p>{`The dialog modal can be used to confirm a user decision or to complete a task. `}</p>
    <Example stories={ModalStories} component={'Dialog'} stretch mdxType="Example" />
    <hr></hr>
    <h2 {...{
      "id": "copy-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#copy-guidelines",
        "aria-label": "copy guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Copy guidelines`}</h2>
    <p>{`When using modals:`}</p>
    <ul>
      <li parentName="ul">{`Include descriptive headings that include the main message of the modal`}</li>
      <li parentName="ul">{`Keep content concise`}</li>
    </ul>
    <p>{`When using buttons with modals, the button labels should make it easy to understand what will happen if the button is used. `}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FilledButton mdxType="FilledButton">Add to basket</FilledButton>
  </Do>
  <Dont center mdxType="Dont">
    <FilledButton mdxType="FilledButton">I want this!</FilledButton>
  </Dont>
    </Guideline>
    <p>{`If a user is cancelling something, like an order, make it clear how they can confirm the cancellation with your button labels.`}</p>
    <p>{`It should be clear which button is confirmation and which is cancellation through your labels.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <FilledButton mdxType="FilledButton">Keep order</FilledButton>
    <FilledButton mdxType="FilledButton">Cancel order</FilledButton>
  </Do>
  <Dont center mdxType="Dont">
    <FilledButton mdxType="FilledButton">Cancel</FilledButton>
    <FilledButton mdxType="FilledButton">Confirm</FilledButton>
  </Dont>
    </Guideline>
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our team can answer any questions about using modals or give you a helping
  hand with your next project.
    </GetInTouch>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      